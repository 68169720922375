import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import { Button, Typography } from 'antd';
import styled from 'styled-components';

const FloatingButtons = styled.div`
  position: sticky;
  margin-right: 20px;
  justify-content: flex-end;
  right: 20px;
  top: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 9;
`;

const PageControls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 9;
  background-color: white;
  padding: 10px;
`;

const SinglePageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: grab;
`;

const ScrollableContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Completely disable default scrolling */
  cursor: grab;
  user-select: none;
  touch-action: none; /* Prevent touch-based scrolling */
`;

export default function SinglePage({ pdf }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const scrollRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const startY = useRef(0);
  const scrollLeft = useRef(0);
  const scrollTop = useRef(0);

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    textLayers.forEach(layer => {
      const { style } = layer;
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function zoomIn() {
    setZoom(prev => prev + 0.1);
  }

  function zoomOut() {
    setZoom(prev => prev - 0.1);
  }

  function handleMouseDown(event) {
    isDragging.current = true;
    startX.current = event.clientX;
    startY.current = event.clientY;
    scrollLeft.current = scrollRef.current.scrollLeft;
    scrollTop.current = scrollRef.current.scrollTop;
    scrollRef.current.style.cursor = 'grabbing';
  }

  function handleMouseMove(event) {
    if (!isDragging.current) return;
    event.preventDefault(); // Prevent default scrolling behavior

    const x = event.clientX;
    const y = event.clientY;
    const walkX = (x - startX.current) * -1; // Reverse direction for natural scrolling
    const walkY = (y - startY.current) * -1;

    scrollRef.current.scrollLeft = scrollLeft.current + walkX;
    scrollRef.current.scrollTop = scrollTop.current + walkY;
  }

  function handleMouseUp() {
    isDragging.current = false;
    scrollRef.current.style.cursor = 'grab';
  }

  return (
    <>
      <FloatingButtons>
        <Button type="primary" shape="circle" onClick={zoomIn}>+</Button>
        <Button type="primary" shape="circle" onClick={zoomOut}>-</Button>
      </FloatingButtons>
      <PageControls>
        <Typography.Text>
          Page {pageNumber} of {numPages || '--'}
        </Typography.Text>
        <Button type="primary" disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </Button>
        <Button type="primary" disabled={pageNumber >= numPages} onClick={nextPage}>
          Next
        </Button>
      </PageControls>
      <SinglePageContainer>
        <ScrollableContainer
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              width={window.innerWidth < 600 ? 300 : 600}
              scale={zoom}
              pageNumber={pageNumber}
              onLoadSuccess={removeTextLayerOffset}
            />
          </Document>
        </ScrollableContainer>
      </SinglePageContainer>
    </>
  );
}

SinglePage.propTypes = {
  pdf: PropTypes.any
};
