import React, { useRef, useState } from 'react';
import { Observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tabs, Typography } from 'antd';
import IssueStore from '../../stores/IssueStore';
import { IssuesTable } from '../../containers/Issues';
import PMFeedbackModal from '../../containers/IssueProfile/PMFeedbackModal';
import PMTable from '../../containers/Vehicles/PMTable';
import { TableStore } from '../../stores/abstract';

const { TabPane } = Tabs;
const { Title } = Typography;

interface CarIssue {
  id: number;
  description: string;
  severity: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

interface FetchParams {
  offset?: number;
  limit?: number;
  sort?: string;
  filter?: Record<string, any>;
}

interface CarIssuesSlideOutProps {
  carId?: number;
  carName?: string;
  mileage?: number;
  engineHours?: number;
  visible: boolean;
  onClose: () => void;
  onIssuesListChange?: () => void;
  currStep?: number;
  viewType?: 'issues' | 'pm' | 'safety';
}

interface SlideOutContainerProps {
  $isVisible: boolean;
}

const SlideOutOverlay = styled.div<SlideOutContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: ${props => props.$isVisible ? 1 : 0};
  visibility: ${props => props.$isVisible ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const SlideOutContainer = styled.div<SlideOutContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  width: 70%; // Default for desktop
  height: 100vh;
  background: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  padding: 24px;
  overflow-y: auto;
  transform: translateX(${props => props.$isVisible ? '0' : '100%'});
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1024px) { // Tablet breakpoint
    width: 80%;
  }

  @media (max-width: 768px) { // Mobile breakpoint
    width: 100%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #333;
    transform-origin: center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: #666;
    }
  }
`;

const SlideOutHeader = styled.div`
  margin-bottom: 24px;
  padding-right: 40px; // Make space for close button
`;

const CarIssuesSlideOut: React.FC<CarIssuesSlideOutProps> = ({
  carId,
  carName,
  visible,
  onClose,
  onIssuesListChange,
  currStep,
  viewType = 'issues'
}) => {
  const [activeTab, setActiveTab] = useState<'issues' | 'pm' | 'safety'>(viewType);
  const pmTableRef = useRef<any>(null);
  const issuesTableRef = useRef<any>(null);

  const handleSearchData = () => {
    if (onIssuesListChange) {
      onIssuesListChange();
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'pm':
        return (
          <div>
            <div className="pm-table-wrapper">
              <PMTable
                carId={carId}
                ref={pmTableRef}
                onIssuesListChange={handleSearchData}
              />
            </div>
            <PMFeedbackModal
              title={IssueStore.currentPMFeedbacks.title}
              currentStep={currStep}
              pmsToReceiveFeedback={IssueStore.currentPMFeedbacks.pmsToReceiveFeedback}
              isModalVisible={IssueStore.currentPMFeedbacks.isModalVisible}
              onSave={() => {
                handleSearchData();
                pmTableRef.current?.fetchData(1, 8, {}, []);
              }}
            />
          </div>
        );

      case 'safety':
        return (
          <div className="safety-inspection-wrapper">
            <PMTable
              ref={pmTableRef}
              carId={carId}
              onIssuesListChange={handleSearchData}
              isSafetyInspection={true}
            />
            <PMFeedbackModal
              title={IssueStore.currentPMFeedbacks.title}
              currentStep={currStep}
              pmsToReceiveFeedback={IssueStore.currentPMFeedbacks.pmsToReceiveFeedback}
              isModalVisible={IssueStore.currentPMFeedbacks.isModalVisible}
              onSave={() => {
                handleSearchData();
                pmTableRef.current?.fetchData(1, 8, {}, []);
              }}
            />
          </div>
        );

      default:
        return (
          <div className="issues-table-wrapper">
            <IssuesTable
              ref={issuesTableRef}
              isEnabled={!_.isNil(carId)}
              id={`car-${carId}`}
              fetchData={(params: FetchParams, store: TableStore) => {
                IssueStore.getIssuesForCarId(
                  {
                    ...params,
                  },
                  store,
                  carId
                );
              }}
              onIssuesListChange={handleSearchData}
              page="carPage"
            />
          </div>
        );
    }
  };

  return (
    <Observer>
      {() => (
        <>
          <SlideOutOverlay $isVisible={visible} onClick={onClose} />
          <SlideOutContainer $isVisible={visible}>
            <CloseButton onClick={onClose} aria-label="Close panel" />

            <SlideOutHeader>
              <Title level={4} style={{ margin: 0 }}>Asset: {carName || '-'}</Title>
            </SlideOutHeader>

            {/* @ts-ignore - antd 3 types */}
            <Tabs
              activeKey={activeTab}
              onChange={(key) => setActiveTab(key as 'issues' | 'pm' | 'safety')}
              type="card"
            >
              {/* @ts-ignore - antd 3 types */}
              <TabPane tab="Issues" key="issues">
                {renderContent()}
              </TabPane>
              {/* @ts-ignore - antd 3 types */}
              <TabPane tab="PM" key="pm">
                {renderContent()}
              </TabPane>
              {/* @ts-ignore - antd 3 types */}
              <TabPane tab="Safety Inspection" key="safety">
                {renderContent()}
              </TabPane>
            </Tabs>
          </SlideOutContainer>
        </>
      )}
    </Observer>
  );
};

CarIssuesSlideOut.propTypes = {
  carId: PropTypes.number,
  carName: PropTypes.string,
  mileage: PropTypes.number,
  engineHours: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onIssuesListChange: PropTypes.func,
  currStep: PropTypes.number,
  viewType: PropTypes.oneOf(['issues', 'pm', 'safety']),
};

export default CarIssuesSlideOut;
