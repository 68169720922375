import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';

import { Col, Row, Button } from 'antd';
import WorkOrderLineItemDetails from './WorkOrderLineItemDetails';
import FormLegendCentered from 'components/Forms/FormLegendCentered';
import WorkOrderDetailsContext from '../../../containers/WorkOrder/WorkOrderDetailsContext';

import { WorkOrderLineItemProvider } from './WorkOrderLineItemContext';

class WorkOrderLineItems extends Component {
  static contextType = WorkOrderDetailsContext;

  static propTypes = {};

  workOrderLinesDetailsRefs = {};

  get currentLineItems() {
    const {
      state: { lineItems },
    } = this.context;
    return lineItems;
  }

  componentDidMount() {
    document.addEventListener('highlightDiscrepancies', this.handleHighlightDiscrepancies);
  }

  componentWillUnmount() {
    document.removeEventListener('highlightDiscrepancies', this.handleHighlightDiscrepancies);
  }

  handleHighlightDiscrepancies = (event) => {
    const discrepancies = event.detail?.discrepancies || [];
    this.highlightDiscrepancyFields(discrepancies);
  };

  highlightDiscrepancyFields = (discrepancies) => {
    if (!discrepancies || !discrepancies.length) return;
    
    // No notification or messages - just highlight the fields
    
    discrepancies.forEach(discrepancy => {
      const description = discrepancy.description;
      
      // Find rows containing the discrepancy description
      const lineItemRows = document.querySelectorAll('.ant-row');
      
      lineItemRows.forEach(row => {
        if (row.textContent.toLowerCase().includes(description.toLowerCase())) {
          // Look specifically for amount inputs, avoiding markup fields
          const amountInputs = this.findAmountInputsInRow(row, discrepancy);
          
          if (amountInputs.length > 0) {
            // Highlight only the amount input
            const amountInput = amountInputs[0]; // Take the first matching amount input
            amountInput.style.border = '2px solid #ff4d4f';
            amountInput.style.backgroundColor = 'rgba(255, 77, 79, 0.05)';
            
            // Store original style to restore later
            amountInput.dataset.highlighted = 'true';
            
            // Scroll to the highlighted input
            amountInput.classList.add('scrollTarget');
            amountInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      });
    });
    
    // Clear highlights after 15 seconds
    setTimeout(() => this.clearHighlights(), 15000);
  };
  
  // Helper to find amount inputs but exclude markup fields
  findAmountInputsInRow = (row, discrepancy) => {
    // Get all numeric inputs in the row
    const allInputs = Array.from(row.querySelectorAll('.ant-input-number-input'));
    
    // Filter to find only amount fields (not markup fields)
    return allInputs.filter(input => {
      // Get the input's parent element to check for labels
      const parentEl = input.closest('.ant-form-item') || input.closest('.ant-col');
      if (!parentEl) return false;
      
      // Check if this is a markup field by looking at the label text
      const labelText = parentEl.textContent.toLowerCase();
      const isMarkupField = 
        labelText.includes('markup') || 
        (parentEl.querySelector('label') && 
         parentEl.querySelector('label').textContent.toLowerCase().includes('markup'));
      
      // Skip markup fields
      if (isMarkupField) return false;
      
      // Look for amount/total fields specifically
      const isAmountField = 
        labelText.includes('amount') || 
        labelText.includes('total') ||
        labelText.includes('cost') ||
        (labelText.includes('price') && !labelText.includes('unit'));
        
      if (isAmountField) {
        // Parse the value to check if it's close to our target
        const rawValue = input.value.replace(/[$,\s]/g, '');
        const value = parseFloat(rawValue);
        
        if (isNaN(value)) return false;
        
        // Check if this value is close to one of our discrepancy values
        const diffFromTotal = Math.abs(value - discrepancy.lineTotal);
        const diffFromCalculated = Math.abs(value - discrepancy.calculated);
        
        return diffFromTotal < 0.1 || diffFromCalculated < 0.1;
      }
      
      return false;
    });
  };
  
  clearHighlights = () => {
    // Remove the highlight class from the rows
    const highlightedInputs = document.querySelectorAll('[data-highlighted="true"]');
    highlightedInputs.forEach(input => {
      input.style.border = '';
      input.style.backgroundColor = '';
      input.removeAttribute('data-highlighted');
    });
    
    // Clear the scroll target class
    const scrollTarget = document.querySelector('.scrollTarget');
    if (scrollTarget) {
      scrollTarget.classList.remove('scrollTarget');
    }
  };

  render() {
    const {
      updateLineItemHeader,
      onChangeLineItemField,
      onAddNewPart,
      onUpdateLaborItem,
      onUpdatePartItem,
      onUpdateMiscellaneousItem,
      onUpdateDefectItem,
      onUpdateServiceItem,
      onRemoveLineItem,
      onSelectSystemCode,
      onSelectAsmCode,
      onAddLineItem,
      shopUsers,
      shopParts,
      shopVendors,
      currentCarIssues,
      onAddLabor,
      onAddPart,
      onAddIssue,
      onAddMiscellaneous,
      onAddDefect,
      onRemoveMiscellaneous,
      onRemoveDefect,
      onRemoveLabor,
      onRemovePart,
      onRemoveIssue,
      isDisabled,
      state: { screenSize, assetDefects, loadingAssetDefects },
    } = this.context;

    const onUserAction = () => {
      console.info('user action...');
      if (this.context.handleFormChange) {
        this.context.handleFormChange();
      }
    };

    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {this.currentLineItems.map((lineItem) => {
            return (
              <div key={lineItem.key}>
                <WorkOrderLineItemProvider
                  value={{
                    lineItem,
                    shopUsers,
                    shopParts,
                    shopVendors,
                    currentCarIssues,
                    onAddLabor,
                    onAddPart,
                    onAddIssue,
                    onAddMiscellaneous,
                    onAddDefect,
                    onRemoveMiscellaneous,
                    onRemoveDefect,
                    onRemoveLabor,
                    onRemovePart,
                    onRemoveIssue,
                    isDisabled,
                    screenSize,
                    assetDefects,
                    loadingAssetDefects
                  }}
                >
                  <WorkOrderLineItemDetails
                    childRef={(ref) =>
                      (this.workOrderLinesDetailsRefs[lineItem.key] = ref)
                    }
                    onChangeServiceTask={(e, value) => {
                      onUserAction();
                      updateLineItemHeader(e, value, lineItem);
                    }}
                    onChangeField={(newVal, fieldName) => {
                      onUserAction();
                      onChangeLineItemField(lineItem.key, newVal, fieldName);
                    }}
                    onAddNewPart={(name) => {
                      onUserAction();
                      onAddNewPart(name || '', lineItem.key);
                    }}
                    onUpdateLaborItem={(labourKey, newVal, whatChanged) => {
                      onUserAction();
                      onUpdateLaborItem(
                        lineItem.key,
                        labourKey,
                        newVal,
                        whatChanged
                      );
                    }}
                    onUpdatePartItem={(partKey, newVal, changedField) => {
                      onUserAction();
                      onUpdatePartItem(
                        lineItem.key,
                        partKey,
                        newVal,
                        changedField
                      );
                    }}
                    onUpdateMiscellaneousItem={(
                      miscKey,
                      newVal,
                      changedField
                    ) => {
                      onUserAction();
                      onUpdateMiscellaneousItem(
                        lineItem.key,
                        miscKey,
                        newVal,
                        changedField
                      );
                    }}
                    onUpdateDefectItem={(defectKey, newVal, changedField) => {
                      onUserAction();
                      onUpdateDefectItem(lineItem.key, defectKey, newVal, changedField);
                    }}
                    onUpdateServiceItem={(serviceKey, newVal) => {
                      onUserAction();
                      onUpdateServiceItem(lineItem.key, serviceKey, newVal);
                    }}
                    onRemoveLineItem={() => {
                      onUserAction();
                      onRemoveLineItem(lineItem.key);
                    }}
                    onSelectSystemCode={(systemCode) => {
                      onUserAction();
                      onSelectSystemCode(lineItem.key, systemCode);
                    }}
                    onSelectAsmCode={(asmCode) => {
                      onUserAction();
                      onSelectAsmCode(lineItem.key, asmCode);
                    }}
                  />
                </WorkOrderLineItemProvider>
              </div>
            );
          })}
        </Col>
        <Col span={24}>
          <FormLegendCentered>
            <Button
              type="primary"
              ghost
              onClick={() => {
                onUserAction();
                onAddLineItem();
              }}
              icon="plus-circle"
            >
              Add New Service Task
            </Button>
          </FormLegendCentered>
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItems, {});

export default observer(WorkOrderLineItems);