import React, { useEffect, useRef } from 'react';
import { PDF } from 'shared/PitstopUI/pdf';

interface MediaPreviewProps {
  media: string;
  isMinimized?: boolean;
  originalWidth?: number;
}

/**
 * @param props
 * @param props.media - The URL or base64 media to preview
 * @param props.isMinimized - Whether the preview is in minimized state
 * @param props.originalWidth - The original width of the preview
 */
const MediaPreview: React.FC<MediaPreviewProps> = ({ media, isMinimized, originalWidth }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    // Ensure PDF containers have proper width
    if (containerRef.current && originalWidth) {
      containerRef.current.style.width = `${originalWidth}px`;
    }
  }, [originalWidth]);
  
  if (!media) {
    return null;
  }
  
  // if is a base64 pdf
  if (media.includes('application/pdf') || media.includes('.pdf')) {
    return <div 
      id="media-preview" 
      ref={containerRef}
      style={{ 
        maxHeight: '80vh', 
        overflow: 'auto', 
        cursor: 'pointer', 
        paddingBottom: 30,
        width: originalWidth ? `${originalWidth}px` : '100%'
      }}>
      <PDF maxHeight="auto" file={media} type="single" />
    </div>;
  }

  const containerStyle = {
    width: originalWidth ? `${originalWidth}px` : '100%',
    overflow: 'auto',
    maxHeight: '80vh',
    position: 'relative' as const
  };

  const imageStyle = {
    width: originalWidth ? `${originalWidth}px` : '100%',
    maxWidth: originalWidth ? `${originalWidth}px` : '100%',
    minWidth: originalWidth ? `${originalWidth}px` : '100%',
    height: 'auto',
    display: 'block',
    objectFit: 'contain' as const
  };

  return (
    <div style={containerStyle}>
      <img
        id="media-preview"
        src={media}
        alt="Media Preview"
        style={imageStyle}
      />
    </div>
  );
};
export default MediaPreview;