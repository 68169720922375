import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { action, decorate, observable, observe } from 'mobx';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import _ from 'lodash';

import moment from 'moment';

import { Layout, Spin } from 'antd';

import 'helpers/HelperFunctions';

import {
  AppStore,
  CurrentUserStore,
  ShopStore,
  CarStore,
  IntegrationStore,
  TableStore,
  GeotabConnector,
  ReportsDataStore,
  WorkOrderStore,
} from 'stores';
import {
  IntegrationCacheStore,
  IssuesTableCacheStore,
  PitstopTableCacheStore,
  ReportCacheStore,
} from 'stores/CacheStore';

import { dealershipRoutes, technicianRoutes, userRoutes } from 'Routes';
import { customFleets } from 'shared';

import { Header, Sidebar } from 'containers/Shell';
import { LoginGeotab, Logout } from 'containers/Auth';
import {
  LoginPage,
  OnboardingPage,
  RegisterPage,
  ResetPasswordPage,
} from 'v3/pages';

import { Loading, SampleDataBar } from 'components';
import {
  GeotabIntegrationForm,
  ProfileForm,
  SamsaraIntegrationForm,
} from 'v3/components/forms/onboarding';
import OtherFormModal from 'containers/Integration/OtherFormModal';
import OktaCallback from 'v3/components/forms/auth/OktaCallback';

import PMFeedbackModal from 'containers/IssueProfile/PMFeedbackModal';

import DeviceStore from 'stores/Classes/DeviceStore';
import getDeviceType from 'shared/deviceTypeUtil';
import { EventBus } from 'stores/EventBus';
import UploadImageModal from 'containers/WorkOrder/modals/UploadImageModal';
// Removed the import for UploadImageModal due to the error
const PMFeedbackModalTyped: any = PMFeedbackModal;

const { Content, Footer } = Layout;

const StyledLayout = styled(Layout)`
  padding: 24px 0px 24px 24px;

  @media (max-width: 992px) {
    padding: 24px 0px;
  }

  @media (max-width: 576px) {
    padding: 0px;
  }
`;

const StyledContent: any = styled(Content)`
  background: #ffffff;
  padding: 24px;
  height: 100%;

  margin-left: ${(props: any) => {
    //Temporarily removing this, probably remove in the future
    const isAdminAndNussbaumShop = false; //ShopStore.currentShop.id === 1065 && CurrentUserStore.user.role === 'admin';
    const marginLeft = isAdminAndNussbaumShop ? '310px' : '240px';
    return props.updatedmarginleft === 'true' ? '0px' : marginLeft;
  }}
  margin-top: 40px;

  @media (max-width: 576px) {
    margin-top: 0px;
  }
`;

const StyledFooter = styled(Footer)`
  && {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  && p {
    margin-bottom: 0;
  }
`;

const LoadingScreen = styled.div`
  margin: auto;
  text-align: center;
`;

let timer: any;

// Update the FileList interface
interface FileList {
  length: number;
  [index: number]: {
    originFileObj: File;
    type: string;
  };
}

interface UploadImageModalProps {
  isVisible: boolean;
  onUpload: (fileList: FileList) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  uploading: boolean;
  progress: number;
  onCancel: () => void;
  isMinimized: boolean;
  onToggleMinimize: () => void;
  onNavigateToEdit?: () => void;
  isProcessingComplete: boolean;
  maskClosable: boolean;
}

interface DashboardWrapperState {
  collapsed: boolean;
  width: number;
  loadingText1: boolean;
  deviceType: { device: string; orientation: string | null };
  isUploadWorkOrderModalVisible: boolean;
  isLoadingWorkOrderUpload: boolean;
  isErrorWorkOrderUpload: boolean;
  uploading: boolean;
  progress: number;
  workOrderImgOcrSignal: AbortController | null;
  scannedWorkOrderPreview: string | null;
  importedInformation: string | null;
  isMinimized: boolean;
  isProcessingComplete: boolean;
  fileList: FileList | null;
}

class DashboardWrapper extends Component<
  RouteComponentProps,
  DashboardWrapperState
> {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  scrollContainer: HTMLElement | null = null;
  originalMediaPreviewTopDistance: number = 0;
  originalMediaPreviewWidth: number = 0;

  scrollToTopAndSaveOriginalMediaPreviewData = (): void => {
    if (this.scrollContainer) {
      this.scrollContainer.scrollTop = 0;
    }
    setTimeout(() => {
      const mediaPreview = document.getElementById('media-preview');
      if (mediaPreview) {
        this.originalMediaPreviewTopDistance = mediaPreview.getBoundingClientRect().top;
        this.originalMediaPreviewWidth = mediaPreview.offsetWidth;
      }
    }, 200);
  };

  constructor(props: any) {
    super(props);
    // load google translate
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    // @ts-ignore
    window.googleTranslateElementInit = this.googleTranslateElementInit;

    // add window resize event listen
    window.addEventListener('resize', this.updateDimensions);

    // register geotab listener if in geotab dashboard
    if (AppStore.isOnGeotabDashboard()) {
      GeotabConnector.registerListener();
    }
  }

  carTableStore = new TableStore();

  state = {
    collapsed: false,
    width: 0,
    loadingText1: true,
    deviceType: { device: '', orientation: null },
    isUploadWorkOrderModalVisible: false,
    isLoadingWorkOrderUpload: false,
    isErrorWorkOrderUpload: false,
    uploading: false,
    progress: 0,
    workOrderImgOcrSignal: null,
    scannedWorkOrderPreview: null,
    importedInformation: null,
    isMinimized: false,
    isProcessingComplete: false,
    fileList: null,
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (CurrentUserStore?.loaded) {
      // @ts-ignore
      pendo.initialize({
        visitor: {
          id: String(CurrentUserStore.user.id), // Required if user is logged in
          email: CurrentUserStore.user.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: CurrentUserStore.user.name, // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: String(ShopStore.currentShop.id), // Required if using Pendo Feedback
          name: ShopStore.currentShop.name, // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
    }

    if (customFleets.gmrv.includes(ShopStore.currentShop.id)) {
      // @ts-ignore
      this.props.history.push('/cars');
    }

    if (
      ![
        '/vehicles',
        '/cars',
        '/issues',
        '/maintenance',
        '/highpriorityvehicles',
        '/location',
        '/contacts',
        '/user-management',
        '/work-order',
        '/work-order/add',
        '/work-order-desktop',
        '/work-order-desktop/add',
        '/warranties',
        '/purchase-orders',
        '/purchase-orders/add',
        '/parts',
        '/parts/add',
      ].includes(
        // @ts-ignore
        this.props.location.pathname
      )
    ) {
      // Enabled Default highpriorityvehicles page for: Fox & James and Sub-Fleets
      const foxAndJamesShopIds = [1147, 1163, 1164, 1165, 1166, 1167, 1168];
      const isFoxAndJames = foxAndJamesShopIds.includes(
        ShopStore.currentShop.id
      );

      if (isFoxAndJames) {
        // @ts-ignore
        return this.props.history.push('/highpriorityvehicles');
      } else {
        const { device } = DeviceStore;

        if (['mobile', 'tablet'].includes(device)) {
          // @ts-ignore
          return this.props.history.push('/work-order');
        }

        // @ts-ignore
        return this.props.history.push('/vehicles');
      }
    }

    if (ShopStore.currentShop.id !== -1) {
      await this.loadDemoCars();

      if (
        CurrentUserStore?.user?.loaded &&
        CurrentUserStore.user.role === 'admin'
      ) {
        if (
          CarStore?.demoCarsTable?.loaded &&
          CarStore.hasDemoCars &&
          CarStore.hasRealCars
        ) {
          await CarStore.unassignDemoCar();
          this.reset();

          ReportsDataStore.reload();
        }

        if (
          CarStore?.demoCarsTable?.loaded &&
          !CarStore.hasDemoCars &&
          !CarStore.hasRealCars
        ) {
          await this.loadDemos();
        }
      }
    }

    // @ts-ignore
    Cookies.set('lastSelectedShop', ShopStore.currentShop.id, { expires: 5 });
  });

  disposer1 = observe(CurrentUserStore, 'loaded', async () => {
    if (CurrentUserStore?.loaded) {
      // in your authentication promise handler or callback
      // eslint-disable-next-line no-undef
      // @ts-ignore
      pendo.initialize({
        visitor: {
          id: String(CurrentUserStore.user.id), // Required if user is logged in
          email: CurrentUserStore.user.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: CurrentUserStore.user.name, // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: String(ShopStore.currentShop.id), // Required if using Pendo Feedback
          name: ShopStore.currentShop.name, // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
      // @ts-ignore
      __insp.push([
        'tagSession',
        {
          email: CurrentUserStore.user.email,
          userId: CurrentUserStore.user.id,
          shop: ShopStore.currentShop.name,
        },
      ]);

      await this.loadDemoCars();
    }
  });

  disposer2 = observe(IntegrationStore, 'modalIntegrationForm', async () => {
    if (IntegrationStore.modalIntegrationForm) {
      AppStore.openModals.set(IntegrationStore.modalIntegrationForm, true);

      await this.loadDemos();
    }
  });

  initLoadingTextInterval = () => {
    timer = setInterval(() => {
      this.setState({ loadingText1: !this.state.loadingText1 });
    }, 3000);
  };

  clearLoadingTextInterval = () => {
    clearInterval(timer);
    timer = false;
  };

  async componentDidMount() {
    // update window dimension
    this.updateDimensions();

    if (
      !['/login', '/register', '/login/geotab', '/reset-password'].includes(
        // @ts-ignore
        this.props.location.pathname
      )
    ) {
      Cookies.set(
        'latestRoute',
        // @ts-ignore
        this.props.location.pathname + this.props.location.search,
        {
          expires: 1,
        }
      );
    }

    // Initialize google analytics page view tracking
    // @ts-ignore
    this.props.history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    await CurrentUserStore.getCurrentUser(false);

    // Check if Motive OAuth code is present in URL
    // @ts-ignore
    if (this.props.location.search.includes('code')) {
      // @ts-ignore
      const code = this.props.location.search.split('=')[1];
      await this.handleMotiveOauthCode(code);
    }

    this.initLoadingTextInterval();

    // Add event listener for showing upload modal
    EventBus.$on('workOrder/SHOW_UPLOAD_MODAL', this.showUploadWorkOrderModal);
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (CurrentUserStore.pending && !timer) {
      this.initLoadingTextInterval();
    } else if (!CurrentUserStore.pending && timer) {
      this.clearLoadingTextInterval();
    }
    if (!AppStore.isOnGeotabDashboard()) {
      let latestRoute = Cookies.get('latestRoute');

      if (
        // @ts-ignore
        this.props.location.pathname + this.props.location.search !==
        latestRoute
      ) {
        if (
          !['/login', '/register', '/login/geotab', '/reset-password'].includes(
            // @ts-ignore
            this.props.location.pathname
          )
        ) {
          Cookies.set(
            'latestRoute',
            // @ts-ignore
            this.props.location.pathname + this.props.location.search,
            {
              expires: 1,
            }
          );
        }

        if (CurrentUserStore.user) {
          let _latestRoute = Cookies.get('latestRoute');

          if (
            _latestRoute !==
            // @ts-ignore
            this.props.location.pathname + this.props.location.search
          ) {
            // @ts-ignore
            this.props.history.push(_latestRoute);
          }

          if (_.isNil(_latestRoute)) {
            // @ts-ignore
            this.props.history.push('/vehicles');
          }
        }
      }
    }

    // Check if we've navigated to work-order/add
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.props.location.pathname === '/work-order/add' &&
      (this.state.scannedWorkOrderPreview || this.state.importedInformation)
    ) {
      // Re-emit the event after navigation is complete
      EventBus.$emit('workOrder/SCAN_COMPLETE', {
        scannedWorkOrderPreview: this.state.scannedWorkOrderPreview,
        importedInformation: this.state.importedInformation,
      });

      // Clear the preview data after successful navigation
      this.setState({
        importedInformation: null,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);

    this.disposer();
    this.disposer1();
    this.disposer2();

    if (timer) {
      this.clearLoadingTextInterval();
    }

    // Remove event listener
    EventBus.$off('workOrder/SHOW_UPLOAD_MODAL', this.showUploadWorkOrderModal);
  }

  updateDimensions = () => {
    let deviceType = getDeviceType();
    let newState = {
      width: window.innerWidth,
      deviceType: {
        ...deviceType,
        orientation: deviceType.orientation ?? null,
      },
    };
    this.setState(newState);
  };

  googleTranslateElementInit = () => {
    // @ts-ignore
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        // @ts-ignore
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      'google_translate_element'
    );
  };

  toggleSideBar = () => {
    this.setState({ collapsed: !this.state.collapsed }, () => {
      EventBus.$emit('dashboardWrapper/SIDEBAR_TOGGLED', this.state.collapsed);
    });
  };

  noAuthRoutes = () => {
    return [
      <Route exact path={['/login', '/']} key="/login" component={LoginPage} />,
      <Route
        exact
        path="/login/geotab"
        key="/login/geotab"
        render={(prop) => (
          <LoginGeotab toggleSideBar={this.toggleSideBar}></LoginGeotab>
        )}
      />,
      <Route
        exact
        path={['/register']}
        key="/register"
        component={RegisterPage}
      />,
      <Route
        exact
        path="/reset-password"
        key="/reset-password"
        component={ResetPasswordPage}
      />,
      <Route exact path="/callback" key="/callback" component={OktaCallback} />,
      <Redirect from="*" to="/login" key="redirect" />,
    ];
  };

  handleMotiveOauthCode = async (code: any) => {
    try {
      if (code) {
        await IntegrationStore.handleMotiveOauth(code);
      }
    } catch (e) {
      // do nothing
    }
  };

  loadDemoCars = async () => {
    try {
      if (ShopStore.currentShop.id !== -1) {
        IntegrationStore.modalIntegrationForm = null;

        // first check if shop has cars
        const hasCars = await CarStore.checkIfShopHasCars(
          ShopStore.currentShop.id
        );

        if (hasCars) {
          // if shop has cars, check if has integration
          IntegrationStore.loaded = false;
          const hasIntegration = await IntegrationStore.checkIfShopHasIntegration(
            ShopStore.currentShop.id
          );
          IntegrationStore.loaded = true;
          const temporaryData = { id: 1, vin: 'DEMO-' };
          if (!hasIntegration) {
            // shop has demo cars linked to it
            // if the shop doesn't have integration, set the CarStore information to tell CarStore to use demo data
            // @ts-ignore
            CarStore.demoCarsTable.demo = true;
            CarStore.demoCarsTable.loaded = true;
            // @ts-ignore
            CarStore.data.set(1, temporaryData);
            CarStore.demoCarsTable.data = [1];

            // if the shop has real cars, unassign demo cars and reset / reload
            if (
              (await CarStore.countShopRealCars(ShopStore.currentShop.id)) > 0
            ) {
              await CarStore.unassignDemoCar();
              this.reset();
              ReportsDataStore.reload();
            }
          } else {
            // if the shop has integration, set the CarStore information to tell CarStore that the shop has real data
            temporaryData.vin = 'integration_set';
            CarStore.demoCarsTable.loaded = true;
            // @ts-ignore
            CarStore.demoCarsTable.demo = false;
            // @ts-ignore
            CarStore.data.set(1, temporaryData);
            CarStore.demoCarsTable.data = [1];
          }
        } else {
          CarStore.demoCarsTable.loaded = true;
        }
      }
    } catch (err) {
      AppStore.addError('Sorry! We are unable to load vehicles data!');
    } finally {
      IntegrationStore.loaded = true;
    }
  };

  loadDemos = async () => {
    // assign demo cars when no cars in fleet
    await CarStore.assignDemoCar(ShopStore.currentShop.id);

    this.reset();

    this.loadDemoCars();

    ReportsDataStore.reload();
  };

  reset = () => {
    PitstopTableCacheStore.reset();
    IssuesTableCacheStore.reset();
    IntegrationCacheStore.reset();
    ReportCacheStore.reset();
    // @ts-ignore
    CarStore.reset();
    CarStore.demoCarsTable.reset();
  };

  handleWorkOrderUpload = async (fileList: FileList) => {
    if (!fileList.length) {
      return;
    }
    try {
      this.setState({
        uploading: true,
        isErrorWorkOrderUpload: false,
        progress: 0,
        fileList,
      });
      const firstFile = fileList[0].originFileObj;

      // convert first file to base64
      const reader = new FileReader();
      reader.readAsDataURL(firstFile);
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const base64 = e.target?.result as string;
        if (!base64) return;

        this.setState({ scannedWorkOrderPreview: base64 });

        const withoutHeaders = base64.split(',')[1];
        // upload the base64 to the server
        try {
          const woOcrAbortController = new AbortController();
          this.setState({
            isLoadingWorkOrderUpload: true,
            progress: 75,
            workOrderImgOcrSignal: woOcrAbortController,
          });
          const data = await WorkOrderStore.workOrderImgOcr(
            {
              base64: withoutHeaders,
              shopId: ShopStore.currentShop.id,
              fileType: firstFile.type,
            },
            woOcrAbortController.signal
          );
          this.setState({
            progress: 100,
            isProcessingComplete: true,
            importedInformation: JSON.stringify(data, null, 4),
            scannedWorkOrderPreview: base64,
          });
        } catch (error) {
          if (error instanceof Error && error.name === 'AbortError') {
            console.log('OCR request aborted');
            this.setState({
              uploading: false,
              progress: 0,
              isErrorWorkOrderUpload: false,
            });
          } else {
            console.log('Error:', error);
            this.setState({
              uploading: false,
              progress: 0,
              isErrorWorkOrderUpload: true,
            });
          }
        } finally {
          this.setState({
            isLoadingWorkOrderUpload: false,
            uploading: false,
          });
        }
      };
    } catch (error) {
      console.error(error);
    }
  };

  handleNavigateToEdit = () => {
    console.log('handleNavigateToEdit - Before:', {
      scannedWorkOrderPreview: this.state.scannedWorkOrderPreview,
      importedInformation: this.state.importedInformation,
      isProcessingComplete: this.state.isProcessingComplete,
      currentPath: this.props.location.pathname,
    });

    // Store values before resetting
    const scannedPreview = this.state.scannedWorkOrderPreview;
    const importedInfo = this.state.importedInformation;
    const targetPath = '/work-order/add';

    // First navigate to ensure the component is ready
    this.props.history.push(targetPath);

    // Use setTimeout to ensure navigation is complete and component is mounted
    setTimeout(() => {
      // Emit the event after navigation
      EventBus.$emit('workOrder/SCAN_COMPLETE', {
        scannedWorkOrderPreview: scannedPreview,
        importedInformation: importedInfo,
      });

      this.scrollToTopAndSaveOriginalMediaPreviewData();

      // Reset all states completely
      this.setState({
        isUploadWorkOrderModalVisible: false,
        isMinimized: false,
        isProcessingComplete: false,
        isLoadingWorkOrderUpload: false,
        isErrorWorkOrderUpload: false,
        uploading: false,
        progress: 0,
        workOrderImgOcrSignal: null,
        scannedWorkOrderPreview: null,
        importedInformation: null,
        fileList: null,
      });
    }, 100); // Small delay to ensure navigation is complete
  };

  onCancelUpload = () => {
    console.log('onCancelUpload - Before:', this.state);

    const signal = this.state.workOrderImgOcrSignal;
    if (signal && 'abort' in signal) {
      (signal as { abort: () => void }).abort();
    }

    this.setState(
      {
        isLoadingWorkOrderUpload: false,
        isErrorWorkOrderUpload: false,
        isProcessingComplete: false,
        progress: 0,
        workOrderImgOcrSignal: null,
        isUploadWorkOrderModalVisible: false,
        scannedWorkOrderPreview: null,
        importedInformation: null,
        uploading: false,
        fileList: null,
      },
      () => {
        console.log('onCancelUpload - After:', this.state);
      }
    );
  };

  showUploadWorkOrderModal = () => {
    this.setState({ isUploadWorkOrderModalVisible: true });
  };

  toggleMinimize = () => {
    this.setState((prevState) => ({
      isMinimized: !prevState.isMinimized,
    }));
  };
  render() {
    const { device } = DeviceStore;
    if (CurrentUserStore.pending) {
      return (
        <>
          <LoadingScreen>
            <div>
              <h1>Welcome to Pitstop Dashboard</h1>
              {// switch between texts to show different loading messages
              this.state.loadingText1 ? (
                <p>
                  Thank you for waiting while our AI Flywheel is hard at work
                  analyzing your data!
                </p>
              ) : (
                <p>
                  Did You Know? Pitstop's software analyzes over 30 Billion data
                  points!
                </p>
              )}
              <Spin />
            </div>
          </LoadingScreen>
        </>
      );
    }

    if (!CurrentUserStore?.loaded) {
      return (
        <>
          <Switch>{this.noAuthRoutes()}</Switch>
        </>
      );
    }

    let routes;

    if (CurrentUserStore?.loaded) {
      switch (CurrentUserStore?.user?.role) {
        case 'admin':
          routes = dealershipRoutes();
          break;
        case 'dealership':
          routes = dealershipRoutes();
          break;
        case 'technician':
          routes = technicianRoutes();
          break;
        case 'user':
        default:
          routes = userRoutes();
      }

      // update the google translate cookie with the current user's preferred language
      if (CurrentUserStore?.user?.settings?.preferredLanguage) {
        Cookies.set(
          'googtrans',
          `/en/${CurrentUserStore.user.settings.preferredLanguage}`,
          {
            expires: 365,
          }
        );
      }
    }

    let updatedmarginleft =
      this.state.collapsed || ['mobile', 'tablet'].includes(device);

    if (
      CurrentUserStore.user.role === 'dealership' &&
      CarStore?.demoCarsTable?.loaded &&
      _.isEmpty(CarStore?.demoCarsTable?.data)
    ) {
      return <OnboardingPage />;
    }

    return (
      <>
        <Layout>
          <Logout />
          <Loading />
          <Header toggleSideBar={this.toggleSideBar} />
          <Sidebar
            collapsed={this.state.collapsed}
            toggleSideBar={this.toggleSideBar}
          />
          <StyledLayout className="dashboard-content">
            <StyledContent updatedmarginleft={updatedmarginleft.toString()}>
              <div id="pendo-helper">&nbsp;</div>

              <SampleDataBar />

              <ProfileForm id="PROFILE_FORM_MODAL" />
              <GeotabIntegrationForm id="geotab-integration-form" />
              <SamsaraIntegrationForm id="samsara-integration-form" />
              <OtherFormModal
                id="other-integration-form"
                shopId={ShopStore.currentShop.id}
                afterCreate={() => {}}
              />

              <UploadImageModal
                isVisible={this.state.isUploadWorkOrderModalVisible}
                onUpload={this.handleWorkOrderUpload}
                isLoading={this.state.isLoadingWorkOrderUpload}
                isError={this.state.isErrorWorkOrderUpload}
                uploading={this.state.uploading}
                progress={this.state.progress}
                onCancel={this.onCancelUpload}
                isMinimized={this.state.isMinimized}
                onToggleMinimize={this.toggleMinimize}
                onNavigateToEdit={this.handleNavigateToEdit}
                isProcessingComplete={this.state.isProcessingComplete}
                maskClosable={false}
              />

              <Switch>{routes}</Switch>
            </StyledContent>
            <StyledFooter style={{ textAlign: 'center' }}>
              <p>Developed by Pitstop Inc &copy; {moment().format('YYYY')}</p>
            </StyledFooter>
          </StyledLayout>
        </Layout>
      </>
    );
  }
}

decorate(DashboardWrapper, {
  carTableStore: observable,
  reset: action,
  loadDemoCars: action,
});

// @ts-ignore
export default withRouter(observer(DashboardWrapper));
