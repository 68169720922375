import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from 'antd';
import { PitstopButton } from 'shared';
import styled from 'styled-components';
import deviceStore from 'stores/Classes/DeviceStore';

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-input {
    border-radius: 5px 0 0 5px;
  }

  button {
    border-radius: 0 5px 5px 0;
  }
`;

class SearchIssue extends Component {
  state = {
    searchOn: 'All',
    searchValue: '',
  };

  _onSearch = () => {
    let _searchOnCols;

    switch (this.state.searchOn) {
      case 'Issue Name':
        _searchOnCols = ['item', 'action'];
        break;
      default:
        _searchOnCols = [
          'item',
          'status',
          'action',
          'causes',
          'symptoms',
          'category',
          'explanation',
          'description',
        ];
    }

    this.props.onSearch({
      searchOn: _searchOnCols,
      searchValue: this.state.searchValue,
    });
  };

  render() {
    const { device } = deviceStore;

    return (
      <SearchWrapper>
        <Input
          style={{ minWidth: device === 'mobile' ? 'auto' : '400px' }}
          placeholder="Search Issues"
          prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
          allowClear
          onChange={(e) => {
            this.setState({
              searchValue: e.target.value,
            });
          }}
          onPressEnter={this._onSearch}
        />
        <PitstopButton onClick={this._onSearch}>Search</PitstopButton>
      </SearchWrapper>
    );
  }
}

SearchIssue.propTypes = {
  car: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  showDownloadButton: PropTypes.bool,
};

export default SearchIssue;
