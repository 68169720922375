import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import styled from 'styled-components';
import { PitstopButton } from 'shared';
import PropTypes from 'prop-types';
import PitstopSimpleTable from 'components/PitstopSimpleTable';
import { Link } from 'react-router-dom';

import {
  Input,
  Row,
  Col,
  Icon,
  Modal,
  Popconfirm,
  Card,
  Checkbox,
  Button,
  Tag,
  notification,
} from 'antd';

import _, { get, isEmpty, set } from 'lodash';

import { WorkOrderStore, ShopStore, AppStore, CurrentUserStore } from 'stores';
import DealershipChooser from 'components/DealershipChooser';
import { Logger } from 'stores/Classes';
import CurrentFilterBadges from 'components/WorkOrder/CurrentFilterBadges';
import FilterDropdown from 'components/FilterDropdown';
import PartStockWidget from 'components/Widgets/PartStockWidget';
// import StatusSettingsModal from 'components/Warranties/StatusSettingsModal';

const Wrapper = styled.div`
  position: relative;

  .clickable-row {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f0f0f0;
`;

const NoImageWrapper = styled.div`
  width: 50px;
  height: auto;
  background-color: #f0f0f0;
  border-radius: 5px;

  height: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 20px;
  padding: 5px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const AisleShelfBinWrapper = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .anticon {
    font-size: 1.1rem;
  }
`;

const CardTitle = styled.div`
  color: #1f3eb8;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const CardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 190px;

  .counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.5rem;

    &.green {
      color: rgb(35, 120, 4);
    }

    &.red {
      color: rgb(212, 56, 13);
    }

    &.blue {
      color: rgb(0, 58, 140);
    }

    .count {
      font-size: 55px;
      font-weight: bold;
    }

    .description {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);

      text-align: center;
    }
  }
`;

const WrapperTable = styled.div`
  @media (max-width: 768px) {
    overflow: 'auto';
    width: 90vw;
  }
`;

const { Search } = Input;
class PartsListPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    allowManager: true,
    allData: [],
    data: [],
    loading: false,
    loadingReports: true,
    partReports: {
      stock: {
        inStock: 0,
        lowStock: 0,
        outOfStock: 0,
      },
    },
    currentSearch: '',
    thumbnailPreviewVisible: false,
    thumbnailPreviewUrl: '',
    currentLocationFilters: [],
    currentStockStatusFilters: [],
    isStatusSettingsModalVisible: false,
    isStatusSettingsModalSaving: false,
    shopSettings: null,
    data: [],
    selectedRows: [],
  };

  text = 'Are you sure you want to delete this part?';

  stockStatusColors = {
    'In Stock': 'green',
    'Low Stock': 'orange',
    'Out of Stock': 'red',
  };

  columns = [
    {
      title: 'Part #',
      dataIndex: 'number',
      key: 'number',
      render: (partNumber, record) => {
        const linkToPart = (
          <Link to={`/parts/${record.id}`}>{partNumber || '-'}</Link>
        );
        const partImages = record.images || '';
        const partImagesArray = partImages.split(',');
        let partThumbnailUrl = '';
        if (partImagesArray.length > 0) {
          partThumbnailUrl = partImagesArray[0];
        }
        const partThumbnail = partThumbnailUrl ? (
          <ImageWrapper
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              this.setState(
                {
                  thumbnailPreviewUrl: partThumbnailUrl,
                },
                () => {
                  this.setState({
                    thumbnailPreviewVisible: true,
                  });
                }
              );
            }}
          >
            <img
              src={partThumbnailUrl}
              alt={partNumber || 'part image'}
              style={{
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </ImageWrapper>
        ) : (
          <NoImageWrapper>
            <Icon
              type="inbox"
              style={{ fontSize: '20px', color: 'lightgrey' }}
            />
          </NoImageWrapper>
        );
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {partThumbnail}
            <div style={{ marginLeft: '10px' }}>{linkToPart}</div>
          </div>
        );
      },
      sorter: (a, b) => {
        if (!a.number) return 1;
        if (!b.number) return -1;
        return a.number.length - b.number.length;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description, row) => {
        if (!description) {
          return row.name;
        }
        return description;
      },
      sorter: (a, b) => {
        const aToSort = a.description || a.name;
        const bToSort = b.description || b.name;
        return aToSort.length - bToSort.length;
      },
    },
    {
      title: 'Status',
      dataIndex: 'stockStatus',
      key: 'stockStatus',
      filters: Object.keys(this.stockStatusColors).map((key) => ({
        text: key,
        value: key,
      })),
      filterIcon: (filterApplied) => {
        // const filterApplied = this.state.currentStockStatusFilters.length > 0;
        return (
          <Icon
            type="filter"
            theme="filled"
            style={{
              color: filterApplied ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ filterByStockStatusVisible: true });
            }}
          />
        );
      },
      // filterDropdown: this.renderStockStatusFilterDropdown,
      render: (status, row) => {
        if (!status) {
          return '--';
        }
        return (
          <Tag color={this.stockStatusColors[status]} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'id_shop',
      key: 'id_shop',
      filters: [],
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByCurrentLocationFiltersVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderLocationFilterDropdown,
      // filterDropdownVisible: this.state.filterByCurrentLocationFiltersVisible,
      render: (id_shop, part) => {
        if (part.shop) {
          return part.shop;
        }
        return id_shop;
      },
      sorter: (a, b) => {
        return a.id_shop - b.id_shop;
      },
    },
    {
      title: 'Aisle / Shelf / Bin',
      dataIndex: 'aisleShelfBin',
      key: 'aisleShelfBin',
      render: (unused, part) => {
        if (!part.aisle && !part.shelf && !part.bin) {
          return 'N/A';
        }
        return (
          <AisleShelfBinWrapper>
            {`${part.aisle || '-'} / ${part.shelf || '-'} / ${part.bin || '-'}`}
          </AisleShelfBinWrapper>
        );
      },
    },
    {
      title: 'Sub-location',
      dataIndex: 'sub_location',
      key: 'sub_location',
      render: (subLocation) => {
        return subLocation || '--';
      },
      sorter: (a, b) => {
        if (!a.sub_location) return 1;
        if (!b.sub_location) return -1;
        return a.sub_location.localeCompare(b.sub_location);
      },
    },
    {
      title: 'Current Stock',
      dataIndex: 'currentStock',
      key: 'currentStock',
      render: (currentStock) => {
        if (!currentStock) return <span style={{ color: 'grey' }}>0</span>;
        return currentStock;
      },
      sorter: (a, b) => {
        if (a.currentStock === undefined || a.currentStock === null) {
          a.currentStock = 0;
        }
        if (b.currentStock === undefined || b.currentStock === null) {
          b.currentStock = 0;
        }
        return a.currentStock - b.currentStock;
      },
    },
    {
      title: 'Unit Cost',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => {
        if (!cost) return <span style={{ color: 'grey' }}>0</span>;
        return cost;
      },
      sorter: (a, b) => {
        if (a.cost === undefined || a.cost === null) {
          a.cost = 0;
        }
        if (b.cost === undefined || b.cost === null) {
          b.cost = 0;
        }
        return a.cost - b.cost;
      },
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (t, row) => {
        const totalCost =
          (row.currentStock ? parseInt(row.currentStock) : 0) *
          (row.cost ? parseFloat(row.cost) : 0);
        if (!totalCost) return <span style={{ color: 'grey' }}>$0.00</span>;
        const formatted = `$${parseFloat(totalCost).toFixed(2)}`;
        return `${formatted}`;
      },
      sorter: (a, b) => {
        const totalCostA =
          (a.currentStock ? parseInt(a.currentStock) : 0) *
          (a.cost ? parseFloat(a.cost) : 0);
        const totalCostB =
          (b.currentStock ? parseInt(b.currentStock) : 0) *
          (b.cost ? parseFloat(b.cost) : 0);
        return totalCostA - totalCostB;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (a, record) => (
        <ActionsWrapper
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Icon
            type="edit"
            onClick={() => this.props.history.push(`/parts/${record.id}`)}
            style={{ marginRight: 4 }}
          />
          {CurrentUserStore.user.allowManager && (
            <Popconfirm
              placement="topRight"
              title={this.text}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                this.onConfirmDeletePart(record);
              }}
              onCancel={(event) => {
                event.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Icon type="delete" />
            </Popconfirm>
          )}
        </ActionsWrapper>
      ),
    },
  ];

  exportColumns = [
    { title: 'Part #', dataIndex: 'number', key: 'number' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Status', dataIndex: 'stockStatus', key: 'stockStatus' },
    { title: 'Location', dataIndex: 'id_shop', key: 'id_shop' },
    { title: 'Shop Name', dataIndex: 'shop', key: 'shop' },
    { title: 'Current Stock', dataIndex: 'currentStock', key: 'currentStock' },
    { title: 'Unit Cost', dataIndex: 'cost', key: 'cost' },
    { title: 'Aisle', dataIndex: 'aisle', key: 'aisle' },
    { title: 'Shelf', dataIndex: 'shelf', key: 'shelf' },
    { title: 'Bin', dataIndex: 'bin', key: 'bin' },
    { title: 'Stock Limit', dataIndex: 'stocklimit', key: 'stocklimit' },
    {
      title: 'Additional Notes',
      dataIndex: 'additionalNotes',
      key: 'additionalNotes',
    },
  ];

  handleExportCSV = () => {
    const { selectedRows, allData } = this.state;

    if (selectedRows.length === 10) {
      // Export all data
      this.exportToCSV(allData);
    } else {
      // Export only selected rows
      this.exportToCSV(selectedRows);
    }
  };

  exportToCSV = (data) => {
    const headers = this.exportColumns.map((col) => col.title).join(',');
    const rows = data
      .map((row) =>
        this.exportColumns
          .map((col) => {
            const value = row[col.dataIndex];
            return `"${
              value !== undefined && value !== null
                ? String(value).replace(/"/g, '""')
                : ''
            }"`;
          })
          .join(',')
      )
      .join('\n');

    const csvContent = `${headers}\n${rows}`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `parts_${new Date().toISOString()}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async componentDidUpdate(prevProps, prevState) {
    let { currentSearch } = this.state;
    if (currentSearch !== prevState.currentSearch) {
      this.handleSearchChangeAndParts();
    }
  }

  searchDebouncer = null;

  handleSearchChangeAndParts = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => {
      this.loadSelectedShopParts(ShopStore.currentShop.id);
    }, 500);
  };

  componentDidMount() {
    AppStore.setSelectedSidebarKey('/parts');
    this.init();
  }

  componentWillUnmount() {
    this.disposer();
  }

  init = async () => {
    await this.loadShopSettings();
    this.loadSelectedShopParts(ShopStore.currentShop.id);
    // load shop parts reports
    this.loadSelectedShopPartsReport(ShopStore.currentShop.id);
  };

  get currentFiltersApplied() {
    return [
      ...this.state.currentLocationFilters,
      ...this.state.currentStockStatusFilters,
    ];
  }

  loadShopSettings = async () => {
    try {
      AppStore.addLoading('Loading shop settings');
      const response = await ShopStore.getShopSettings(
        ShopStore.currentShop.id
      );
      const shopSettings = get(response, 'result[0].settings', {});
      this.setState({ shopSettings });
    } catch (error) {
      Logger.error(error);
    } finally {
      AppStore.removeLoading('Loading shop settings');
    }
  };

  updateShopFilteringOptions(shopIdNameObject) {
    const arrayOfShopObjects = Object.keys(shopIdNameObject).map((key) => ({
      value: key,
      text: shopIdNameObject[key],
    }));
    this.setState({
      allShopEcosystem: arrayOfShopObjects,
    });
    this.columns = this.columns.map((column) => {
      if (column.key === 'id_shop') {
        column.filters = arrayOfShopObjects;
      }
      return column;
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (filters.id_shop) {
      // filtering by shop
      this.setState(
        {
          currentLocationFilters: filters.id_shop.map((key) => {
            const shop = this.state.allShopEcosystem.find(
              (s) => s.value === key
            );
            return {
              key: key,
              label: shop?.text || key,
            };
          }),
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
    }
    if (filters.stockStatus) {
      // filtering by stock status
      this.setState(
        {
          currentStockStatusFilters: filters.stockStatus.map((key) => {
            return {
              key: key,
              label: key,
            };
          }),
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
    }
  };

  handleFilterReset = (clearFilters, type = 'currentLocation') => {
    clearFilters();
    if (type === 'currentLocation') {
      this.setState(
        {
          currentLocationFilters: [],
          filterByCurrentLocationFiltersVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    } else if (type === 'stockStatus') {
      this.setState(
        {
          currentStockStatusFilters: [],
          filterByStockStatusVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
    }
  };

  handleCurrentLocationFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentLocationFilters: selectedKeys.map((key) => {
          const shop = this.currentLocationFilters.find((s) => s.value === key);
          return {
            key: key,
            label: shop?.text || key,
          };
        }),
        filterByCurrentLocationFiltersVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleStockStatusFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentStockStatusFilters: selectedKeys.map((key) => {
          return {
            key: key,
            label: key,
          };
        }),
        filterByStockStatusVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  renderLocationFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <FilterDropdown className="assigned-to-dropdown">
        {/* For each filterOptions display a checkbox and the name of the filterOptions */}
        <div className="filter-dropdown-list">
          {this.state.currentLocationFilters.map((shop) => {
            return (
              <div className="filter-dropdown-list-item" key={shop.value}>
                <Checkbox
                  checked={selectedKeys.includes(shop.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedKeys([...selectedKeys, shop.value]);
                    } else {
                      setSelectedKeys(
                        selectedKeys.filter((key) => key !== shop.value)
                      );
                    }
                  }}
                >
                  {shop.text}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="filter-dropdown-actions">
          <Button
            onClick={() =>
              this.handleFilterReset(clearFilters, 'currentLocation')
            }
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.handleCurrentLocationFilterChange(selectedKeys, confirm)
            }
          >
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };

  renderStockStatusFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <FilterDropdown className="stock-status-dropdown">
        {/* For each filterOptions display a checkbox and the name of the filterOptions */}
        <div className="filter-dropdown-list">
          {Object.keys(this.stockStatusColors).map((status) => {
            return (
              <div className="filter-dropdown-list-item" key={status}>
                <Checkbox
                  checked={selectedKeys.includes(status)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedKeys([...selectedKeys, status]);
                    } else {
                      setSelectedKeys(
                        selectedKeys.filter((key) => key !== status)
                      );
                    }
                  }}
                >
                  {status}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="filter-dropdown-actions">
          <Button
            onClick={() => this.handleFilterReset(clearFilters, 'stockStatus')}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.handleStockStatusFilterChange(selectedKeys, confirm)
            }
          >
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };

  onChangeCurrentFilters = () => {
    // update table filter based on the filters selected
    const filters = {
      location: [],
      stockStatus: [],
    };
    this.state.currentLocationFilters.forEach((filter) => {
      filters['location'].push(filter.key);
    });
    this.state.currentStockStatusFilters.forEach((filter) => {
      filters['stockStatus'].push(filter.key);
    });
    // update the allColumns
    this.columns = this.columns.map((column) => {
      if (column.key === 'id_shop') {
        return {
          ...column,
          // filters: this.state.currentLocationFilters,
          filteredValue: filters['location'],
        };
      } else if (column.key === 'stockStatus') {
        return {
          ...column,
          // filters: this.state.currentStockStatusFilters,
          filteredValue: filters['stockStatus'],
        };
      }
      return column;
    });
    // filter the data based on location and stock status
    const filteredData = this.state.allData.filter((part) => {
      let isLocationMatch = true;
      let isStockStatusMatch = true;
      if (filters['location'].length > 0) {
        isLocationMatch = filters['location'].includes(part.id_shop.toString());
      }
      if (filters['stockStatus'].length > 0) {
        isStockStatusMatch = filters['stockStatus'].includes(part.stockStatus);
      }
      return isLocationMatch && isStockStatusMatch;
    });

    this.setState({
      data: filteredData,
    });
  };

  loadSelectedShopParts = async (shopId) => {
    try {
      this.setState({ loading: true });
      const partsSearch = {
        search: this.state.currentSearch,
      };
      const data = await WorkOrderStore.getShopParts(shopId, partsSearch);
      // hydrate shop parts with shop info
      const shopNames = await ShopStore.getShopNames(
        _.uniq(data.map((part) => part.id_shop))
      );
      this.updateShopFilteringOptions(shopNames);
      data.forEach((part) => {
        part.shop = shopNames[part.id_shop];
      });
      this.setState({ data, allData: data });
      // if current shop is in the list of shops, add it to the filters
      if (ShopStore.currentShop.id) {
        const shopIds = Object.keys(shopNames).map((key) => key.toString());
        if (shopIds.includes(ShopStore.currentShop.id.toString())) {
          this.setState(
            {
              currentLocationFilters: [
                {
                  key: ShopStore.currentShop.id.toString(),
                  label: shopNames[ShopStore.currentShop.id],
                },
              ],
            },
            () => {
              this.onChangeCurrentFilters();
            }
          );
        } else {
          // clear filters
          this.setState(
            {
              currentLocationFilters: [],
            },
            () => {
              this.onChangeCurrentFilters();
            }
          );
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  loadSelectedShopPartsReport = async (shopId) => {
    try {
      this.setState({ loadingReports: true });
      const data = await WorkOrderStore.getShopPartReports(shopId);
      if (data?.stock) {
        this.setState({
          partReports: {
            ...this.state.partReports,
            stock: {
              inStock: data.stock.inStock,
              lowStock: data.stock.lowStock,
              outOfStock: data.stock.outOfStock,
            },
          },
        });
      }
      // this.updateStockStatusOptions();
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ loadingReports: false });
    }
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.isAdmin()) return;
    this.setState(
      {
        currentSearch: '',
      },
      () => {
        this.init();
      }
    );
  });

  navigateToAdd = () => {
    this.props.history.push('/parts/add');
  };

  onConfirmDeletePart = async (record) => {
    try {
      const partId = record.id;
      await WorkOrderStore.deletePartOnCustomEndpoint(partId);
      AppStore.addSuccess('The part was deleted!');
      this.loadSelectedShopParts(ShopStore.currentShop.id);
    } catch (error) {
      console.error(error);
      AppStore.addError('There was an error deleting the part');
    }
  };

  updateStatusSettings = async ({
    warrantyStatusChangeDays,
    warrantyStatusChangeMiles,
    partStockLowStockLessThan,
  }) => {
    try {
      this.setState({ isStatusSettingsModalSaving: true });
      const shopSettings = isEmpty(this.state.shopSettings)
        ? {}
        : this.state.shopSettings;
      set(shopSettings, 'warrantyStatusChangeDays', warrantyStatusChangeDays);
      set(shopSettings, 'warrantyStatusChangeMiles', warrantyStatusChangeMiles);
      set(shopSettings, 'partStockLowStockLessThan', partStockLowStockLessThan);
      await ShopStore.upsertShopSettings(
        ShopStore.currentShop.id,
        shopSettings
      );
      notification.success({
        message: 'Success',
        description: 'Status settings updated successfully',
      });
      this.setState({
        isStatusSettingsModalVisible: false,
      });
      await this.loadShopSettings();
      this.init();
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating status settings',
      });
    } finally {
      this.setState({ isStatusSettingsModalSaving: false });
    }
  };

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };
    return (
      <Fragment>
        <Wrapper>
          {CurrentUserStore.allowManager && (
            <div style={{ position: 'absolute', right: '0px' }}>
              <PitstopButton icon="plus" onClick={this.navigateToAdd}>
                Add Part
              </PitstopButton>
            </div>
          )}
          <h1>Parts</h1>

          <DealershipChooser />

          {/* Widget for In Stock, Low Stock and Out of Stock */}
          {/* Widget showing In Warranty, Out of Warranty, Warranty Ending Soon */}
          {ShopStore.currentShop.id === 374 && (
            <Row gutter={8}>
              <Col span={8}>
                <Card>
                  <CardTitle>Warranty</CardTitle>
                  <CardBody>
                    <div className="counter green">
                      <div className="count">23</div>
                      <div className="description">In Warranty</div>
                    </div>
                    <div className="counter red">
                      <div className="count">11</div>
                      <div className="description">Out of Warranty</div>
                    </div>
                    <div className="counter blue">
                      <div className="count">17</div>
                      <div className="description">Ending Soon</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row gutter={8} style={{ marginTop: 18 }}>
            <Col xs={24} sm={6}>
              <PartStockWidget
                loading={this.state.loadingReports}
                inStock={this.state.partReports.stock.inStock}
                lowStock={this.state.partReports.stock.lowStock}
                outOfStock={this.state.partReports.stock.outOfStock}
                onClickLabel={(label) => {
                  // filter by stock status
                  this.setState(
                    {
                      currentStockStatusFilters: [
                        {
                          key: label,
                          label: label,
                        },
                      ],
                    },
                    () => {
                      this.onChangeCurrentFilters();
                    }
                  );
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 10, marginTop: 10 }} gutter={[16, 16]}>
            <Col span={6}>
              <Search
                placeholder="Search by # or description"
                onChange={(event) => {
                  this.setState({
                    currentSearch: event.target.value,
                  });
                }}
              />
            </Col>
          </Row>

          <div
            style={{
              display: 'flex',
            }}
          >
            {/* <Button
              type="primary"
              size="small"
              ghost
              onClick={() => {
                this.setState({
                  isStatusSettingsModalVisible: true,
                });
              }}
              style={{ marginRight: 10 }}
            >
              Status Settings
            </Button> */}
            <CurrentFilterBadges
              currentFiltersApplied={this.currentFiltersApplied}
              onClearAllFilters={() => {
                this.setState(
                  {
                    currentLocationFilters: [],
                    currentStockStatusFilters: [],
                  },
                  () => {
                    this.onChangeCurrentFilters();
                  }
                );
              }}
              onCloseTag={(filter) => {
                this.setState(
                  {
                    currentLocationFilters: this.state.currentLocationFilters.filter(
                      (f) => f.key !== filter.key
                    ),
                    currentStockStatusFilters: this.state.currentStockStatusFilters.filter(
                      (f) => f.key !== filter.key
                    ),
                  },
                  () => {
                    this.onChangeCurrentFilters();
                  }
                );
              }}
            />
            <Button
              type="primary"
              size="small"
              style={{ marginLeft: '10px' }}
              onClick={this.handleExportCSV}
              disabled={this.state.selectedRows.length === 0}
            >
              Export CSV
            </Button>
          </div>

          <WrapperTable>
            <PitstopSimpleTable
              rowKey="id"
              onRowClick={(record) =>
                this.props.history.push(`/parts/${record.id}`)
              }
              onChange={this.handleTableChange}
              loading={this.state.loading}
              data={this.state.data}
              columns={this.columns}
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ selectedRows });
                },
              }}
              rowClassName="clickable-row"
            />
          </WrapperTable>

          <Modal
            visible={this.state.thumbnailPreviewVisible}
            footer={null}
            closeIcon={<CloseIcon type="close" />}
            onCancel={() => {
              this.setState({ thumbnailPreviewVisible: false });
            }}
            onOk={() => {
              this.setState({ thumbnailPreviewVisible: false });
            }}
          >
            <img
              src={this.state.thumbnailPreviewUrl}
              alt="thumbnail"
              style={{
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </Modal>

          {/* <StatusSettingsModal
            visible={this.state.isStatusSettingsModalVisible}
            saving={this.state.isStatusSettingsModalSaving}
            initialData={this.state.shopSettings}
            onOk={(newSettings) => {
              this.updateStatusSettings(newSettings);
            }}
            onCancel={() => {
              this.setState({
                isStatusSettingsModalVisible: false,
              });
            }}
          /> */}
        </Wrapper>
      </Fragment>
    );
  }
}

export default observer(PartsListPage);
