/* eslint-disable react/prop-types */
import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

// Create a wrapper div for styling
const SelectWrapper = styled.div<{ customStyle?: string }>`
  width: 100%;
  .ant-select {
    width: 100%;
    ${props => props.customStyle}
  }
`;

interface SelectOption {
  label: string | React.ReactNode;
  value: string | number;
  disabled?: boolean;
}

// Define props without extending from SelectProps to avoid type conflicts
interface PitstopSelectProps {
  options: SelectOption[];
  value?: any;
  onChange?: (value: any, option: any) => void;
  onSearch?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  mode?: 'multiple' | 'tags';
  showSearch?: boolean;
  optionFilterProp?: string;
  filterOption?: boolean | ((input: string, option: any) => boolean);
  className?: string;
  customStyle?: string;
  [key: string]: any; // Allow other props to be passed through
}

const defaultFilterOption = (input: string, option: any) => {
  if (option.children) {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else if (option.props && option.props.children) {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  return false;
};

// Use React.createElement to avoid TypeScript errors with JSX
const PitstopSelect: React.FC<PitstopSelectProps> = ({
  options,
  value,
  onChange,
  onSearch = (value: string) => { /* empty function */ },
  placeholder = '-',
  disabled = false,
  loading = false,
  mode,
  showSearch = true,
  optionFilterProp = 'children',
  filterOption = defaultFilterOption,
  customStyle,
  className,
  customOptions = false,
  ...rest
}) => {
  return (
    <SelectWrapper customStyle={customStyle}>
      {React.createElement(
        Select,
        {
          value,
          onChange,
          onSearch,
          placeholder,
          disabled,
          loading,
          mode,
          showSearch,
          optionFilterProp,
          filterOption,
          allowClear: true,
          className,
          ...rest
        },
        options.map(option =>
          React.createElement(
            Select.Option,
            {
              key: option.value,
              value: option.value,
              disabled: option.disabled
            },
            option.label
          )
        )
      )}
    </SelectWrapper>
  );
};

export default PitstopSelect;
