import { observable, computed, decorate } from 'mobx';
import { notification } from 'antd';
import _ from 'lodash';

import PitstopCookie from './PitstopCookie';

class AppStoreC {
  errorMessages = observable([]);
  messages = observable([]);
  showLogoutModal = observable.box(false);
  showLoginModal = observable.box(false);
  loggingIn = observable.box(false);
  loggedIn = observable.box(false);
  dialogOpen = observable.box(false);
  openModals = new Map();

  prevUrl = observable.box(undefined);

  selectedSidebarKey = '';

  setSelectedSidebarKey = (key) => {
    this.selectedSidebarKey = key;
  };

  isLoading = computed(() => {
    return this.messages.length !== 0;
  });

  hasError = computed(() => {
    return this.errorMessages.length !== 0;
  });

  reset = () => {
    this.errorMessages = [];
  };
  addLoading(message) {
    this.messages.push(message);
  }

  removeLoading(message) {
    this.messages.splice(this.messages.indexOf(message), 1);
  }

  setDialogStatus = (status) => {
    this.dialogOpen.set(status);
  };

  areCookiesEnable = async () => {
    const cookieTestName = 'test_cookie';
    PitstopCookie.set(cookieTestName, 'true', {
      samesite: 'none',
      secure: true,
    });
    const testValue = await PitstopCookie.get(cookieTestName);
    PitstopCookie.remove(cookieTestName);
    return testValue === 'true';
  };

  isOnGeotabDashboard() {
    const windowName = window.name;
    if (windowName === 'pitstop') return true;
    const ssoVersion = this.getGeotabSSOVersion();
    return ssoVersion !== undefined;
  }

  getGeotabSSOVersion() {
    const frameName = window.name;
    if (_.isNil(frameName)) return undefined;
    const frameNameArray = frameName.split('_');
    if (frameNameArray.length !== 2) return undefined;
    const versionValue = parseInt(frameNameArray[1]);
    return versionValue;
  }

  addNotification = (message) => {
    notification['info']({
      message: 'Notification',
      description: message,
    });
  };
  addError = (message, otherOptions = {}) => {
    if (this.errorMessages.includes(message)) return;
    this.errorMessages.push(message);

    notification['error']({
      message: 'Error',
      description: message,
      ...otherOptions,
    });

    setTimeout(() => {
      this.errorMessages = this.errorMessages.filter((msg) => msg !== message);
    }, 5000);
  };
  addSuccess = (message) => {
    notification['success']({
      message: 'Success',
      description: message,
    });
  };
  addWarning = (message) => {
    notification['warning']({
      message: 'Warning',
      description: message,
    });
  };
}

decorate(AppStoreC, {
  openModals: observable,
  selectedSidebarKey: observable,
});

var AppStore = new AppStoreC();
export default AppStore;
