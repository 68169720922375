import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table } from 'antd';
import CustomHeaderCell from 'shared/PitstopUI/PitstopTable/CustomHeaderCell';

const TableContainer = styled(Table)`
  .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
  }
`;

const PitstopSimpleTable = ({
  rowKey,
  loading,
  data,
  columns,
  onRowClick,
  rowClassName,
  onChange,
  rowSelection,
  pagination,
}) => {
  return (
    <TableContainer
      rowKey={rowKey}
      scroll={{ x: true }}
      loading={loading}
      columns={columns}
      dataSource={data}
      onRowClick={onRowClick}
      onChange={onChange}
      rowClassName={rowClassName}
      rowSelection={rowSelection}
      pagination={pagination}
      components={{
        header: {
          cell: CustomHeaderCell,
        },
      }}
    />
  );
};

PitstopSimpleTable.propTypes = {
  rowKey: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  onChange: PropTypes.func,
  rowSelection: PropTypes.object,
  pagination: PropTypes.object,
};

PitstopSimpleTable.defaultProps = {
  rowKey: 'id',
  loading: false,
  data: [],
  columns: [],
  onRowClick: () => {},
  onChange: () => {},
  rowClassName: '',
  rowSelection: null,
  pagination: undefined,
};

export default PitstopSimpleTable;
